@media screen and (max-width: 480px) {

    .product-gallery{
        margin: 10px;
        overflow-x: hidden;
      }
      
      .images-grid-container_1{
      display: flex;
      align-items: center;
      margin: 0px;
      }
      .images-grid-wrap_1{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
      }
      .images-grid_1{
        width: 100%;
        height: 100%;
        background-color: aliceblue;
        border: 1px solid black;
        place-items: center
      }
      .lamp-first-row{
        width: 100%;
        height: 100%;
      }
      
      /* second row  */
      .images-grid-container_2{
        display: flex;
        align-items: center;
        margin: 0px;
        }
        .images-grid-wrap_2{
          display: grid;
          grid-template-columns: 1fr;
          gap: 10px;
          margin-top: 10px;
      
        }
        .images-grid_2{
          width: 100%;
          height: 100%;
          background-color: aliceblue;
          border: 1px solid black;
          place-items: center
        }
        .lamp-second-row{
          width: 100%;
          height: 100%;
        }
      
        /* bollard s */
      
        .images-grid-container_3{
          display: flex;
          align-items: center;
          margin: 0px;
          }
          .images-grid-wrap_3{
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
            margin-top: 10px;
        
          }
          .images-grid_3{
            width: 100%;
            height: 100%;
            background-color: aliceblue;
            border: 1px solid black;
            place-items: center
          }
          .bollard-first-row{
            width: 100%;
            height: 100%;
          }
      
          /* ellite */
          .images-grid-container_4{
            display: flex;
            align-items: center;
            margin: 0px;
            }
            .images-grid-wrap_4{
              display: grid;
              grid-template-columns: 1fr;
              gap: 10px;
              margin-top: 10px;
          
            }
            .images-grid_4{
              width: 100%;
              height: 100%;
              background-color: aliceblue;
              border: 1px solid black;
              place-items: center
            }
            .ellite-first-row{
              width: 100%;
              height: 100%;
            }
        }