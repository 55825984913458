.info_cards {
    background: #fff;
    margin: 30px;
  }
  .info__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
    gap: 20px;
  }
  .cards--titles {
    padding: 70px;
  }
  .exp {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
  }
  .title-- {
    color: #000;
    font-size: 28px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
  }
  .info {
    width: 100%;
    height: 100%;
    padding: 30px;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
    /* -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
    /* filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  }
  .info h2 {
    text-align: center;
    padding-bottom: 15px;
    color: #252e48;
  }
  .info p {
    color: #252e48;
    line-height: 28px;
  }
  .orange-card{
    background-color: #FFB84C; 
  
  }
  @media screen and (max-width: 480px) {
    .info__wrapper {
      overflow-x: hidden;
      display: grid;
      grid-template-columns: 1fr ;
      place-items: center;
      gap: 20px;
    }
  }