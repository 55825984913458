.footer_container {
  display: flex;
  background-color: #15172b;
  padding: 50px;
  margin-top: 50px;
  justify-content: space-between;
  padding: 20px 100px 20px 100px;
  gap: 20px;
  font-family: "Poppins", sans-serif;
}
.footer-logo {
  width: 200px;
  height: 200px;
}
.address___footer {
  width: 250px;
  margin-top: 20px;
}
.site__year {
  color: darkgray;
  background-color: #15172b;
  text-align: center;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.social_icons {
  color: azure;
}
.icns {
  margin: 20px;
  cursor: pointer;
}
.icns:hover {
  color: #f2921d;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .footer_container {
    overflow-x: hidden;
    display: block;
    background-color: #15172b;
    margin-top: 20px;
    padding: 50px 10px 20px 10px;
  }
  .footer__name {
    text-align: center;
  }
  .address___footer {
    width: 400px;
    text-align: start;
    overflow: hidden;
    margin-top: 20px;
  }
  .social_icons {
    color: azure;
    text-align: center;
  }
  .icns:hover {
    color: #f2921d;
    cursor: pointer;
  }
}
