.product-gallery {
    /* margin: 10px; */
  }
  /* .services-names {
    padding: 6px 8px;
    width: 150px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #f2921d;
    box-sizing: border-box;
    margin: 20px;
  } */
  
  .images-grid-container_1 {
    display: flex;
    align-items: center;
    margin: 0px 50px 0px 50px;
  }
  .images-grid-wrap_1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .images-grid_1 {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    border: 1px solid black;
    place-items: center;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  .lamp-first-row {
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  
  /* second row  */
  .images-grid-container_2 {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .images-grid-wrap_2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
  }
  .images-grid_2 {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    border: 1px solid black;
    place-items: center;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  .lamp-second-row {
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  
  /* bollard s */
  
  .images-grid-container_3 {
    display: flex;
    align-items: center;
    margin: 0px 50px 0px 50px;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  .images-grid-wrap_3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 10px;
  }
  .images-grid_3 {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
    border: 1px solid black;
    place-items: center;
  }
  .bollard-first-row {
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  
  /* ellite */
  .images-grid-container_4 {
    display: flex;
    align-items: center;
    margin: 0px 50px 0px 50px;
  }
  .images-grid-wrap_4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  
    gap: 10px;
    margin-top: 10px;
  }
  .images-grid_4 {
    width: 100%;
    height: 100%;
    background-color: aliceblue;
    border: 1px solid black;
    place-items: center;
  }
  .ellite-first-row {
    width: 100%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  
  }
  