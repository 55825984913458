@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200&display=swap');


.contact_body {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* height: 100vh; */
  background-color: #fff;
  gap: 30px;
  font-family: 'Poppins', sans-serif;

}

.address {
  text-align: start;
  margin: 20px;
  padding: 30px;
  font-family: 'Poppins', sans-serif;

}
.city{
  color: #f2921d;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-align: start;
  line-height: 24px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;


}
.location{
  color: #15172b;
  font-family: sans-serif;
  font-size: 16px;
  text-align: start;
  line-height: 20px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;

}
.phone{
  color: #15172b;
  font-family: sans-serif;
  font-size: 16px;
  text-align: start;
  line-height: 20px;
  padding: 20px; 
  font-family: 'Poppins', sans-serif;


}
.email{
  color: #15172b;
  font-family: sans-serif;
  font-size: 16px;
  text-align: start;
  line-height: 20px;
  padding-left: 20px;
  font-family: 'Poppins', sans-serif;

}

.form {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: auto;
  padding: 60px;
  width: 500px;
  font-family: 'Poppins', sans-serif;
}

.title {
  color: #f2921d;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
  text-align: center;
}

.subtitle {
  color: #eee;
  text-align: center;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 100%;
}
::placeholder{
  font-size: 15px;
}

.ic1 {
  margin-top: 40px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.textarea {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 18px;
  height: 150%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
  resize: none;
}
.cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 80px;
}

.cut-short {
  width: 60px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}
.textarea:focus ~ .cut,
.textarea:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #dc2f55;
}

.textarea:focus ~ .placeholder,
.textarea:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.textarea:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.textarea:focus ~ .placeholder {
  color: #dc2f55;
}
.submit {
  background-color: #f2921d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  outline: 0;
  text-align: center;
  width: 100%;
}

.submit:active {
  background-color: #f2921d;
}
 
 

@media screen and (max-width: 480px) {
  .contact_body {
    overflow-x: hidden;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    /* height: 100vh; */
    background-color: #fff;
    gap: 30px;
  }

  .form {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: auto;
  padding: 60px;
  width: auto;
}
  
}