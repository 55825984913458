@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}
.bootstrap-nav {
  padding: 0;
  margin-bottom: 100px;
}
@media screen and (max-width: 480px) {
  .App {
    overflow-x: hidden;
  }
  .bootstrap-nav {
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }
  .website-logo {
    width: 120px;
    height: 80px;
    overflow-x: hidden;
  }
}

.website-logo {
  width: 150px;
  height: 90px;
}
.bootstrap-nav {
  outline: none;
  background-color: #fff transparent !important;
  padding: 0;
  overflow-x: hidden;

}
.bootstrap-nav {
  color: black;
  text-decoration: none;
  overflow-x: hidden;
}

.get-in-touch {
  color: #252e48;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.highlighted-text {
  color: orange;
}
.link {
  color: aliceblue;
}
.link:hover {
  transition: all 0.2s ease-out;
  color: #f2921d;
}
