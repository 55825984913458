@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200&display=swap');
.about__container {
  background: #fff;
  margin-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 100px;
}

.about__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  gap: 20px;
}
.about_head {
  color: #252e48;
  font-size: 28px;
  line-height: 24px;
  text-align: start;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}
.about_content {
  line-height: 24px;
  color: #000;
  padding: 20px;
  font-family: 'Poppins', sans-serif;

}
.about_pic {
  margin-top: 60px;
  box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
}
.about_pic__ {
  border: 1px solid #252e48;
  box-shadow: 0 6px 20px rgba(120, 122, 125, 0.17);
  border-radius: 10px;
}
.read_more {
  border-radius: 10px;
  font-family: "Avenir Next";
  font-size: 1rem;
  padding: 0.3rem 3rem;
  color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  /* background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, black, orange);
    background-origin: border-box;
    background-clip: content-box, border-box; */
  box-shadow: 2px 1000px 1px #fff inset;
  background-color: #f2921d;
  margin-top: 1rem;
  cursor: pointer;

}
.read_more:hover {
  box-shadow: none;
  color: white;
}
.extra-content{
  font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 480px) {
  .about__container {
    overflow-x: hidden;
    background: #fff;
    padding: 0;
    margin: 0;
  }

  .about__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 20px;
  }
  .about_head {
    color: #252e48;
    font-size: 28px;
    line-height: 24px;
    text-align: start;
  }
  .about_content {
    line-height: 24px;
    color: #252e48;
  }
}
