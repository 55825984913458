.container {
    position: relative;
    text-align: center;
    color: white;
  }
  .home__img__container {
    width: 100%;
    height: 100%;
  }
  .home__img {
    width: 100%;
    height: 100%;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 30px;
  }
  .centered > h1 {
    color: #f2921d;
    font-size: 100px;
  }
  .centered > p {
    color: #fff;
    font-size: 32px;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    text-align: center;
  }
  
  @media screen and (max-width: 480px) {
    .container {
      text-align: center;
      color: white;
      overflow-x: hidden;
    }
    .home__img__container {
      width: 100%;
      height: 100%;
      margin-top: 100px;
    }
    .home__img {
      width: 100%;
      height: 100%;
    }
    .centered {
      position: fixed;
    }
    .centered > h1 {
      display: none;
    }
    .centered > p {
      display: none;
    }
  }
  